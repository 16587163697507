//Steps for the walkthrough component (walks users through app)
export const WALKTHROUGH_STEPS = [
    {
        selector: '[data-walkthrough="browser"]',
        content: 'Search for a track or navigate through the genres / playlists, then click 1 next to the track to load it into Deck 1'
    },
    {
        selector: '[data-walkthrough="mainWaveform"]',
        content: 'This is the waveform of the currently playing track, colored by frequency. Red:Bass, Green:Mids, Blue:Highs. The current position of the song is dictated by the white bar in the center. The white lines throughout the track are the auto-determined beats of the song.'
    },
    {
        selector: '[data-walkthrough="trackDetails"]',
        content: 'View important information about the track on this deck here - including title, artist, bpm (tempo), and the waveform of the full track'
    },
    {
        selector: '[data-walkthrough="play"]',
        content: 'Press play to start playing your track'
    },
    {
        selector: '[data-walkthrough="sync"]',
        content: 'When both tracks are playing, pressing sync moves the playback and tempo of track to align its beats with the other track, synchronizing the playback'
    },
    {
        selector: '[data-walkthrough="cuePoint"]',
        content: 'A cue point is a marker that you can set at a specific time within a track - after you set this, you can jump to this point by pressing the number in this section.'
    },
    {
        selector: '[data-walkthrough="beatJump"]',
        content: 'Measure (or beat) Jump allows you to skip back or forward a certain amount of measures, maintaining the phrasing of the playback'
    },
    {
        selector: '[data-walkthrough="tempoSlider"]',
        content: 'Move this tempo slider to change the tempo of the song to speed it up or slow it down manually'
    },
    {
        selector: '[data-walkthrough="crossfader"]',
        content: 'The crossfader controls the volume of both decks. Drag it to the left to only hear Deck 1, drag it to the right to only hear Deck 2'
    },
];

//Default playlists that display when the app opens (Soundcloud uses these users to publish Soundcloud-curated playlists, so we list all of each users' playlists)
export const EXPLORE_OPTIONS = [
    {
        'id':'603452106',
        'username': 'The Peak (EDM)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000599540127-vevupo-large.jpg'
        //The Peak
    },
    {
        'id':'603472665',
        'username': 'Main Room (House)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000599543151-z8b0kf-large.jpg'
        //Main Room
    },
    {
        'id':'603451386',
        'username': 'Hustle (Rap)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000599539242-59qkoq-large.jpg'
        //Hustle
    },
    {
        'id':'603904224',
        'username': 'Circuits (Underground Electronic)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000599548212-bskjwf-large.jpg',
        //Circuits
    },
    {
        'id':'603468462',
        'username': 'RAW (Soundcloud Rap)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000599540463-tdj7kv-large.jpg',
        //RAW
    },
    {
        'id':'603470913',
        'username': 'AURAS (R&B)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000599540979-tz4n2g-large.jpg',
        //AURAS
    },
    {
        'id':'603473631',
        'username': 'Scenes (Indie)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000600496689-dbv36h-large.jpg',
        //Scenes
    },
    {
        'id':'603474543',
        'username': 'Vibrations (Global)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000599544672-44jjvl-large.jpg',
        //Vibrations
    },
    {
        'id':'603448746',
        'username': 'Shine (Pop)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000599537340-vpck6o-large.jpg',
        //Shine
    },
    {
        'id':'603901965',
        'username': 'La Onda (Latin)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000599544873-mo2gan-large.jpg',
        //La Onda
    },
    {
        'id':'603469821',
        'username': 'VS (Lyrical Rap)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000599538921-su9whq-large.jpg',
        //VS
    },
    {
        'id':'603902775',
        'username': 'Amped (Rock)',
        'avatar_url': 'https://i1.sndcdn.com/avatars-000599867865-k6uf89-large.jpg',
        //Amped
    },
    {
        'id':'6762617',
        'username': 'BCAST',
        'avatar_url': 'https://i1.sndcdn.com/avatars-0zaC2qmagSTVYP9H-D9iVXA-large.jpg',
        //BCAST
    },
];

export const KEY_COLORS = {
    'Am':'purple',
    'C': 'purple',
    'Bbm': 'magenta',
    'Db': 'magenta',
    'Bm': 'red',
    'D': 'red',
    'Cm': 'volcano',
    'Eb': 'volcano',
    'Dbm': 'orange',
    'E': 'orange',
    'Dm': 'gold',
    'F': 'gold',
    'Ebm': 'lime',
    'Gb': 'lime',
    'Em': 'green',
    'G': 'green',
    'Fm': 'cyan',
    'Ab': 'cyan',
    'Gbm': 'blue',
    'A': 'blue',
    'Gm': 'geekblue',
    'Bb': 'geekblue',
    'Abm': '',
    'B': ''
}