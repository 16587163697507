export const renderAudioAtFrequencies = (buffer, low, high, sampleRate = null) => {
    //Renders audio in an offlinecontext between a low and a high frequency - used for audio frequency analysis

    //Sample Rate needs to be above 3000
    if (sampleRate === null) {
        sampleRate = buffer.sampleRate ? buffer.sampleRate : 48000;
    }

    console.log('rendering')
    return new Promise(function (resolve, reject) {

        let OfflineContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;
        let offlineContext = new OfflineContext(2, sampleRate * buffer.duration, sampleRate);

        let source = offlineContext.createBufferSource();
        source.buffer = buffer.get();

        //Run the output through a lowpass filter to filter out anything but low end
        let lowpass = offlineContext.createBiquadFilter();
        lowpass.type = "lowpass";
        lowpass.frequency.value = high;
        lowpass.Q.value = 1;
        source.connect(lowpass);

        //Run the output through a highpass filter to filter out bass line (so only kick drums analyzed)
        let highpass = offlineContext.createBiquadFilter();
        highpass.type = "highpass";
        highpass.frequency.value = low;
        highpass.Q.value = 1;
        lowpass.connect(highpass);

        highpass.connect(offlineContext.destination);
        source.start();

        offlineContext.startRendering().then(function (renderedBuffer) {
            resolve(renderedBuffer)
        });
    })
}