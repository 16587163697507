import React from 'react';
import './Browser.css';
import '../App.css';
import { Table, Button, Row, Col, Input, Menu, Spin, PageHeader, Alert, Tag } from 'antd';
import { DatabaseOutlined, RocketOutlined, UserOutlined, HeartOutlined, ArrowLeftOutlined, PlayCircleFilled } from '@ant-design/icons';
import { EXPLORE_OPTIONS, KEY_COLORS } from '../data/constants';

import * as API from '../API';

const SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];

const abbreviateNumber = (number) => {
    // what tier? (determines SI symbol)
    const tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return number;

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(0) + suffix;
}

export default function Browser(props) {
    const [tracks, setTracks] = React.useState([]);
    const [playlists, setPlaylists] = React.useState([]);
    const [users, setUsers] = React.useState(EXPLORE_OPTIONS);
    const [loading, setLoading] = React.useState(false);
    const [history, setHistory] = React.useState([{ text: `Explore`, value: 'EXPLORE', type: 'PAGE' }]);
    const [error, setError] = React.useState('');

    const tColumns = [
        { title: 'Title', dataIndex: 'title', key: 'title' },
        {
            title: 'Artist', dataIndex: 'artist', key: 'artist',
            render: (artist, song) => (
                <div>
                    <span style={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => selectUser({ id: song.source_artist_id, username: song.artist })} type="link">
                        {song.artist}
                    </span>
                </div>
            )
        },
        { title: 'BPM', dataIndex: 'bpm', key: 'bpm' },
        { title: 'Key', dataIndex: 'key', key: 'key', render: (key) => <Tag color={KEY_COLORS[key]}>{key}</Tag>},
        {
            title: 'Stats', dataIndex: 'likes', key: 'likes',
            render: (likes, song) => (
                <div>
                    <div key="plays" className="secondaryText" style={{ display: 'block' }}>
                        <span style={{ whiteSpace: 'nowrap' }}><PlayCircleFilled />&nbsp;{abbreviateNumber(song.plays)}</span>
                    </div>
                    <div key="likes" className="secondaryText" style={{ display: 'block' }}>
                        <span style={{ whiteSpace: 'nowrap' }}><HeartOutlined />&nbsp;{abbreviateNumber(song.likes)}</span>
                    </div>
                </div>
            )
        },
        {
            title: 'Load', dataIndex: 'source_id', key: 'source_id',
            render: (source_id, song) => (
                <div>
                    <Button onClick={() => props.loadTrack(1, song)} type="primary" ghost style={{ margin: '2px' }}>1</Button>
                    <Button onClick={() => props.loadTrack(2, song)} type="primary" ghost style={{ margin: '2px' }}>2</Button>
                </div>
            )
        },
    ];

    const onSearchTermChange = (term) => {
        resetResults();
        setLoading(true);
        setHistory([...history, { text: `Search: ${term}`, value: term, type: 'SEARCH' }])
        Promise.all([
            API.searchTracks(term),
            API.searchPlaylists(term),
            API.searchUsers(term)
        ]).then(results => {
            setTracks(results[0]);
            setPlaylists(results[1]);
            setUsers(results[2])
        }).catch(err => {
            setError(err.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getCollection = () => {
        setHistory([...history, { text: `Collection`, value: 'COLLECTION', type: 'PAGE' }])
        resetResults()
        setLoading(true)
        API.getCollection().then(res => {
            setTracks(res)
        }).catch(err => {
            setError(err.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const selectPlaylist = (playlist) => {
        resetResults()
        setHistory([...history, { text: `Playlist: ${playlist.title} by ${playlist.user.username}`, value: playlist, type: 'PLAYLIST' }])
        setLoading(true)
        API.getPlaylist(playlist.id)
            .then(res => {
                setTracks(res.tracks);
            }).catch(err => {
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const selectUser = (user) => {
        resetResults();
        setHistory([...history, { text: `Artist: ${user.username}`, value: user, type: 'USER' }])
        setLoading(true);
        Promise.all([
            API.getUserTracks(user.id),
            API.getUserPlaylists(user.id),
        ]).then(results => {
            setTracks(results[0]);
            setPlaylists(results[1]);
        }).catch(err => {
            setError(err.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const goBackInHistory = () => {
        if (history.length > 1) {
            const previousHistory = history[history.length - 2];
            if (previousHistory.type === 'USER') {
                selectUser(previousHistory.value);
            }
            else if (previousHistory.type === 'PLAYLIST') {
                selectPlaylist(previousHistory.value);
            }
            else if (previousHistory.type === 'SEARCH') {
                onSearchTermChange(previousHistory.value)
            }
            else if (previousHistory.type === 'PAGE') {
                if (previousHistory.value === 'EXPLORE') {
                    showExploreOptions()
                }
                else if (previousHistory.value === 'COLLECTION') {
                    getCollection();
                }
            }
            setHistory(history.filter((val, index) => index !== history.length - 1));
        }
    }

    const showExploreOptions = () => {
        resetResults();
        setUsers(EXPLORE_OPTIONS);
        setHistory([...history, { text: `Explore`, value: 'EXPLORE', type: 'PAGE' }])
    }

    const resetResults = () => {
        setError('');
        setTracks([]);
        setPlaylists([]);
        setUsers([])
    }

    return (
        <Row data-walkthrough="browser">
            <Col xs={{ span: 24 }} md={{ span: 6 }} xl={{ span: 4 }}>
                <Input.Search onSearch={onSearchTermChange} placeholder="Search SoundCloud" size="large" className="darkInput"></Input.Search>

                <Menu theme="dark" mode="inline" selectable={false}>
                    <Menu.Item icon={<DatabaseOutlined />} key="searchresults" onClick={(e) => getCollection()}>Collection</Menu.Item>
                    <Menu.Item icon={<RocketOutlined />} key="explore" onClick={(e) => showExploreOptions()}>Explore</Menu.Item>
                </Menu>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 18 }} xl={{ span: 20 }}>
                {history.length > 0 &&
                    <div>
                        <PageHeader
                            className="results-header"
                            onBack={() => goBackInHistory()}
                            backIcon={<ArrowLeftOutlined style={{ color: 'white' }} />}
                            title={<span style={{ color: 'white' }}>{history[history.length - 1].text}</span>}
                            subTitle=''
                        />
                    </div>
                }

                <div style={{ padding: '10px' }}>

                    {loading && <div className='loader-container'><Spin size="large" /></div>}

                    {error && <Alert
                        message="Error loading music"
                        description={error}
                        type="error"
                        showIcon
                    />}

                    {/*SEARCH RESULTS VIEW - TRACKS*/}
                    {tracks.length > 0 &&
                        <div style={{ marginBottom: '15px' }}>
                            <h2 style={{ color: 'white' }}>Tracks</h2>
                            <Table
                                size="small"
                                columns={tColumns}
                                dataSource={tracks}
                                loading={loading}
                                rowKey='id'
                                className='darkTable'
                                pagination={false}
                            />
                        </div>
                    }

                    {/*SEARCH RESULTS VIEW - Users*/}
                    {users.length > 0 &&
                        <div style={{ marginBottom: '15px' }}>
                            <h2 style={{ color: 'white' }}>Artists</h2>
                            {users.map(item => {
                                const background = `linear-gradient(191deg, #${Math.random().toString(16).substr(-6)}, #${Math.random().toString(16).substr(-6)})`;
                                return (<div className='playlist' key={item.id} onClick={(e) => selectUser(item)}>
                                    {item.avatar_url ? (
                                        <img className='playlistImage' src={item.avatar_url} alt='img' />
                                    ) : (<div className='playlistImage' style={{ background: background }}></div>)}
                                    <span style={{ color: 'white', fontWeight: 'bold' }}>{item.username}</span>
                                    <br />
                                    <span style={{ color: 'white' }}><UserOutlined style={{ fontSize: '13px' }} />&nbsp;{item.followers_count ? abbreviateNumber(item.followers_count) : '-'}</span>
                                </div>)
                            })}
                        </div>
                    }

                    {/*SEARCH RESULTS VIEW - PLAYLISTS*/}
                    {playlists.length > 0 &&
                        <div style={{ marginBottom: '15px' }}>
                            <h2 style={{ color: 'white' }}>Playlists</h2>
                            {playlists.map(item => {
                                const background = `linear-gradient(191deg, #${Math.random().toString(16).substr(-6)}, #${Math.random().toString(16).substr(-6)})`;
                                return (<div className='playlist' key={item.id} onClick={(e) => selectPlaylist(item)}>
                                    {item.artwork_url ? (
                                        <img className='playlistImage' src={item.artwork_url} alt='img' />
                                    ) : (<div className='playlistImage' style={{ background: background }}></div>)}
                                    <span style={{ color: 'white', fontWeight: 'bold' }}>{item.title}</span>
                                    <br />
                                    <span style={{ color: 'white', }}>{item.user.username}</span>
                                    <br />
                                    <span style={{ color: 'white' }}><HeartOutlined style={{ fontSize: '13px' }} />&nbsp;{item.likes_count ? abbreviateNumber(item.likes_count) : '-'}</span>
                                </div>)
                            })}
                        </div>}
                </div>
            </Col>
        </Row>
    )
}