import React from 'react';
import { Player, Clock, Buffer, EQ3} from 'tone';
import * as Tone from 'tone';
import '../App.css';

//ANT components
import { Button, Space, Row, Col, Spin, Tag, Typography } from 'antd';
import { PauseOutlined, CaretRightOutlined, SyncOutlined } from '@ant-design/icons';
import * as API from '../API';
import { getTempo } from '../audio_analysis/bpm';
import { buildWaveform } from '../audio_analysis/waveform';
import { KEY_COLORS } from '../data/constants';

const {Text} = Typography;


//Global Vars
//Performance
const refreshRate = 1000;
//const waveform_resolution = 350;
const waveform_fps = 30;
//const track_waveform_fps = 2;
const track_waveform_resolution = 1000;

class Deck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Deck metadata
            number: props.number,
            //Track metadata
            track: props.track,
            //Display
            track_waveform: [],
            color: props.color,
            wavezoom: 18,
            loading: false,
            displayFactor: 'desktop',
            //Playback
            position: 0,
            playbackRate: 1,
            playing: false,
            volume: 1,
            bpm: '',

            //Dragging
            dragging: false
        };

        this.mainWaveformCanvasRef = React.createRef();
        this.trackWaveformCanvasRef = React.createRef();
        this.trackWaveformTimelineCanvasRef = React.createRef();

        //Create player
        this.deck = new Player({
            //this.deck = new GrainPlayer({
            playbackRate: this.playbackRate,
            url: null
        });

        //Create our EQ (low, mid, high filters), then send to master
        this.mixer = new EQ3(0, 0, 0).toMaster();
        //Connect our deck to this EQ (to use as mixer)
        this.deck.chain(this.mixer);

        //Too performance-intenstive to keep around all the time - need to move this somewhere else
        //FX RACK
        /*this.reverb = new Freeverb({ roomSize: 0.95, dampening: 2500 }).toMaster();
        this.fx_eq = new EQ3(-30, -9, -9);
        this.fx_eq.chain(this.reverb);*/

        //Create clock to track time
        this.clock = new Clock(function (time) { }, refreshRate);
    }

    componentDidMount() {
        window.addEventListener("resize", this.onWindowResize.bind(this));
        this.onWindowResize();

        //Draw Main Waveform loop
        requestAnimationFrame(() => this.renderMainWaveform());
        //Draw the overlay over the track view for position
        requestAnimationFrame(() => this.renderFullTimelineView());
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.track.source_id !== this.props.track.source_id) {
            this.setState({
                track: this.props.track
            })
            this.loadTrack(this.props.track.source_id);
        }
        if (prevProps.gain !== this.props.gain) {
            this.deck.volume.value = this.props.gain;
        }
    }

    render() {
        return (
            <React.Fragment>
                {/*Main Waveform*/}
                <div style={{ width: '100%' }}>
                    {/*Loading Overlay*/}
                    {this.state.loading ?
                        <div style={{ position: 'fixed', top: 85 * (this.state.number - 1) + 'px', left: 0, height: '85px', width: '100%', backgroundColor: 'rgba(255,255,255,.2)', textAlign: 'center', verticalAlign: 'middle', zIndex: 1001 }}>
                            <Spin size="large" style={{ verticalAlign: 'middle', top: '50%', position: 'relative', transform: 'translateY(-50%)' }} />
                        </div>
                        : ''
                    }
                    <canvas ref={this.mainWaveformCanvasRef} height={100} width={2000} data-walkthrough="mainWaveform" style={{ top: 85 * (this.state.number - 1) + 'px', height: '85px', position: 'fixed', backgroundColor: 'black', left: 0, width: '100%', cursor: 'pointer', border: '1px solid grey', zIndex: 1000 }}
                        onMouseDown={this.waveform_dragstart}
                        onMouseUp={this.waveform_dragend}
                        onMouseMove={this.waveform_drag}
                        onMouseLeave={this.waveform_dragend}
                    ></canvas>
                    <div style={{ height: '85px', position: 'fixed', left: 0, width: '25px', zIndex: 1001, top: 85 * (this.state.number - 1) + 'px', backgroundColor: '#1A1A1B', borderRight: '1px solid #818384', boxSizing: 'border-box', textAlign: 'center' }}>
                        <div style={{ marginTop: '25px', fontWeight: 'bold', fontSize: '20px', color: 'white' }}>{this.state.number}</div>
                    </div>
                </div>

                {/*Deck View*/}
                <div style={{ padding: '5px', backgroundColor: 'black', color: 'white', border: '1px solid #818384', textAlign: 'left', position: 'relative' }}>
                    {/*Loading Overlay*/}
                    {this.state.loading ?
                        <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', zIndex: 5, backgroundColor: 'rgba(255,255,255,.2)', textAlign: 'center', verticalAlign: 'middle' }}>
                            <Spin size="large" style={{ verticalAlign: 'middle', top: '50%', position: 'relative', transform: 'translateY(-50%)' }} />
                        </div>
                        : ''
                    }
                    {/*Details*/}
                    <Row gutter={16} style={{ fontSize: '15px', paddingBottom: '3px' }} data-walkthrough="trackDetails" justify="space-between" wrap={false}>
                        <Col flex="auto">
                            <Row justify="space-between" wrap={false}>
                                <Col flex="auto">
                                    <Text ellipsis style={{color:'white'}}>{this.state.track.title}</Text>
                                </Col>
                                <Col style={{textAlign:'right'}} flex="none">
                                    {this.state.track.key &&
                                    <Tag color={KEY_COLORS[this.state.track.key]} style={{marginRight:'5px', marginLeft:'5px'}}>
                                        <span style={{fontSize:'17px'}}>&#119070;</span>
                                        <span>&nbsp;{this.state.track.key}</span>
                                    </Tag>}
                                    {this.state.bpm &&
                                        <Tag color="rgb(45,45,45)" style={{fontSize:'15px', margin:'0px', width:'58px', textAlign:'center'}}>{this.state.bpm}</Tag>
                                    }
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <div>{this.state.track.artist}</div>
                                <div>{this.state.bpm ? ((100 * this.state.bpm / this.state.track.bpm) - 100).toFixed(2) + '%' : ''}</div>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <canvas
                                        ref={this.trackWaveformCanvasRef}
                                        height="40"
                                        width="1200"
                                        style={{ width: '100%', height: '30px', backgroundColor: 'black', cursor: 'pointer' }}
                                    />
                                    <canvas
                                        ref={this.trackWaveformTimelineCanvasRef}
                                        height="40"
                                        width="1200"
                                        style={{ width: '100%', height: '30px', position: 'absolute', top: 0, left: 0, cursor: 'pointer' }}
                                        onClick={(e) => this.onTrackWaveformSeek(e)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="none" style={{ textAlign: 'right' }} data-walkthrough="tempoSlider">
                            <div style={{ textAlign: 'center' }}>
                                {/*Tempo Slider*/}
                                <input
                                    className="tempo_slider"
                                    type="range"
                                    min="0"
                                    max="200"
                                    step="0.1"
                                    style={{ marginTop: '25px' }}
                                    value={this.state.bpm}
                                    onChange={(e) => this.onTempoSlide(e)}
                                />
                                <br />
                                <br />
                                <Button onClick={this.reset_BPM} size="small" ghost style={{ marginTop: '5px' }}>Reset</Button>
                            </div>


                        </Col>
                    </Row>

                    {/*Main buttons*/}
                    <Row>
                        <Col style={{ padding: '5px' }}>
                            <div style={{ width: '100%', height: '16px', backgroundColor: 'rgb(30,30,30)', textAlign: 'center', fontSize: '10px' }}>PLAYBACK</div>
                            <Space size="middle">
                                {/*Play/Pause*/}
                                {this.state.playing ?
                                    <Button onClick={this.pause} type="danger" icon={<PauseOutlined />} disabled={this.state.loading} data-walkthrough="play">Pause</Button>
                                    :
                                    <Button onClick={this.play} type="primary" icon={<CaretRightOutlined />} disabled={this.state.loading} data-walkthrough="play">Play</Button>
                                }
                                <Button onClick={this.sync} type="default" ghost icon={<SyncOutlined />} disabled={this.state.loading} data-walkthrough="sync">Sync</Button>
                            </Space>
                        </Col>
                        {/*Cue Points*/}
                        <Col style={{ padding: '5px' }} data-walkthrough="cuePoint">
                            <div style={{ width: '100%', height: '16px', backgroundColor: 'rgb(30,30,30)', textAlign: 'center', fontSize: '10px' }}>CUE POINTS</div>
                            <Button onClick={(e) => this.cuePoint('1', e)} type="primary" ghost={1 in this.state.track.cue_points ? false : true} disabled={this.state.loading}>1</Button>
                            <Button onClick={(e) => this.cuePoint('2', e)} type="primary" ghost={2 in this.state.track.cue_points ? false : true} disabled={this.state.loading}>2</Button>
                            <Button onClick={(e) => this.cuePoint('3', e)} type="primary" ghost={3 in this.state.track.cue_points ? false : true} disabled={this.state.loading}>3</Button>
                            {/*<Button onClick={(e) => this.cuePoint('4', e)} type="primary" ghost={4 in this.state.track.cue_points ? false : true} disabled={this.state.loading}>4</Button>
                                <Button onClick={(e) => this.cuePoint('5', e)} type="primary" ghost={5 in this.state.track.cue_points ? false : true} disabled={this.state.loading}>5</Button>*/}
                        </Col>

                        {/*Measure Jump*/}
                        <Col style={{ padding: '5px' }} data-walkthrough="beatJump">
                            <div style={{ width: '100%', height: '16px', backgroundColor: 'rgb(30,30,30)', textAlign: 'center', fontSize: '10px' }} >MEASURE JUMP</div>
                            <Button onClick={(e) => this.beatJump(-16, e)} type="danger" ghost disabled={this.state.loading}>4</Button>
                            {/*<Button onClick={(e) => this.beatJump(-8, e)} type="danger" ghost disabled={this.state.loading}>2</Button>*/}
                            <Button onClick={(e) => this.beatJump(-4, e)} type="danger" ghost disabled={this.state.loading}>1</Button>
                            <Button onClick={(e) => this.beatJump(-1, e)} type="danger" ghost disabled={this.state.loading}>1/4</Button>
                            <Button onClick={(e) => this.beatJump(1, e)} ghost disabled={this.state.loading}>1/4</Button>
                            <Button onClick={(e) => this.beatJump(4, e)} ghost disabled={this.state.loading}>1</Button>
                            {/*<Button onClick={(e) => this.beatJump(8, e)} ghost disabled={this.state.loading}>2</Button>*/}
                            <Button onClick={(e) => this.beatJump(16, e)} ghost disabled={this.state.loading}>4</Button>
                        </Col>

                        {/*Auxiliary buttons*/}
                        {/*<Col style={{ padding: '5px' }}>
                            <div style={{ width: '100%', height: '16px', backgroundColor: 'rgb(30,30,30)', textAlign: 'center', fontSize: '10px' }}>CONFIG</div>
                            <Button onClick={this.reGrid} ghost>ReGrid</Button>
                            </Col>*/}
                        {/*<Col style={{padding:'5px'}}>
                        <Button onClick={(e) => this.toggleReverb('on', e)} ghost>Reverb On</Button>
                        </Col>
                        <Col style={{padding:'5px'}}>
                        <Button onClick={(e) => this.toggleReverb('off', e)} ghost>Reverb Off</Button>
                        </Col>*/}
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    //CONTROLLER METHODS
    onBPMChange = (event) => {
        this.set_BPM(event.target.value)
    }

    onTempoSlide = (event) => {
        this.set_BPM(event.target.value)
    }

    onTrackWaveformSeek = (event) => {
        const canvas = this.trackWaveformCanvasRef.current;
        let rect = canvas.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let width = rect.right - rect.left;
        let proportion = x / width;
        let position = this.deck.buffer.duration * proportion;

        this.skipTo(position);
    }

    //PLAYBACK///////////////////////////////////////////////////////////////////////
    play = () => {
        var now = Tone.now();
        //Position of our clock
        let position = this.getPosition();
        this.clock.start(now, position * refreshRate);

        if (position < 0) {
            //Set the deck to start in n seconds at 0, adjusted for tempo variation
            this.deck.start(-position * (this.state.track.bpm / this.state.bpm) + now, 0);
        }
        else {
            this.deck.start(now, position);
        }
        this.setState({
            playing: true
        })
    }
    pause = () => {
        var now = Tone.now();
        this.clock.pause(now);
        this.deck.stop(now);
        this.setState({
            playing: false
        })
    }

    getPosition = () => {
        return this.clock.ticks / refreshRate;
    }
    set_BPM = (bpm) => {
        var now = Tone.now();
        var original_bpm = this.state.track.bpm;
        var playbackRate = bpm / original_bpm;

        this.setState({
            bpm: bpm,
            playbackRate: playbackRate
        })
        this.deck.playbackRate = playbackRate;
        this.clock.frequency.setValueAtTime(playbackRate * refreshRate, now);
    }
    reset_BPM = () => {
        this.set_BPM(this.state.track.bpm);
    }

    getPhase = () => {
        let position = this.getPosition();
        let grid_start = this.state.track.grid_start;
        let bpm = this.state.track.bpm;

        var start = grid_start - (4 * 100 * 60) / bpm;
        let time_elapsed = Math.abs(position - start);
        var beats = time_elapsed * (bpm / 60);

        //Beat Phase
        let beat_phase = beats % 1;
        return { 'phase': beat_phase, 'bpm': this.state.bpm };
    }

    beatJump(beats) {
        let timePerBeat = (1 / this.state.track.bpm) * 60
        let position = this.getPosition();
        this.skipTo(position + timePerBeat * beats);
    }


    skipTo(position) {
        var now = Tone.now();
        this.clock.setTicksAtTime(position * refreshRate, now);
        if (position > 0) {
            this.deck.stop(now);
            if (this.state.playing) {
                this.deck.start(now, position);
            }
        }
        else {
            if (this.state.playing) {
                this.deck.stop(now);
                //Set the deck to start in n seconds at 0, adjusted for tempo variation
                this.deck.start(-position * (this.state.track.bpm / this.state.bpm) + now, 0);
            }
            else {
                this.deck.seek(0, now)
            }
        }
    }

    sync = () => {
        let otherTrackData = this.props.getExternalTempo();
        let myPhase = this.getPhase()['phase'];

        let difference = otherTrackData['phase'] - myPhase;
        //Change the direction of this shift if the phase is closer moving in the other direction
        if (Math.abs(difference) > .5) {
            difference = difference - Math.sign(difference)
        }

        let time_correction = (difference / this.state.track.bpm) * 60

        let position = this.getPosition();
        let new_position = position + time_correction;
        this.set_BPM(otherTrackData['bpm'])
        this.skipTo(new_position);
    }

    cuePoint = (number) => {
        let cue_points = this.state.track.cue_points;
        //Skip to cue point if it exists
        if (number in cue_points) {
            let newTime = cue_points[number];
            this.skipTo(newTime);
        }
        //Otherwise, create the cue point at the current position
        else {
            cue_points[number] = this.getPosition();
            let track = this.state.track;
            track['cue_points'] = cue_points;
            this.setState({
                track: track
            })
        }
    }

    waveform_drag = (event) => {
        if (this.state.dragging) {
            let canvas = this.mainWaveformCanvasRef.current;
            let rect = canvas.getBoundingClientRect();
            let x = event.clientX - rect.left;
            let diff = this.state.drag_x_init - x;

            let width = rect.right - rect.left;
            let proportion = diff / width;
            let position = this.state.drag_position_init + proportion * 8;

            this.skipTo(position);
        }
    }

    waveform_dragstart = (event) => {
        this.pause();
        let canvas = this.mainWaveformCanvasRef.current;
        let rect = canvas.getBoundingClientRect();
        let x = event.clientX - rect.left;

        this.setState({
            dragging: true,
            drag_position_init: this.getPosition(),
            drag_x_init: x
        })
    }
    waveform_dragend = (event) => {
        this.setState({
            dragging: false
        })
    }

    reGrid = () => {
        let self = this;
        let grid_start = this.getPosition();
        buildTrackTimeline(grid_start, this.deck.buffer.duration, this.state.track.bpm).then(function (result) {
            let track = self.state.track;
            track.timeline = result;
            track.grid_start = grid_start;
            self.setState({
                track: track
            })
        })
    }

    //EFFECTS /////////////////////////////////////////////////
    toggleReverb = (state, e) => {
        if (state === 'on') {
            //Connect our mixer to our FX rack
            this.mixer.chain(this.fx_eq);
        }
        else {
            this.mixer.disconnect(this.fx_eq);
        }
    }

    //TRACK LOADING / ANALYSIS/////////////////////////////////////////////////////////////////
    loadTrack = async (track_id) => {
        this.clearWaveforms();
        this.setState({
            //Display
            track_waveform: [],
            //Playback
            position: 0,
            playbackRate: 1,
            playing: false,
            bpm: '',
            loading: true
        })
        this.pause();
        this.skipTo(0);

        let track = this.state.track;

        const data = await API.analyzeSoundcloudTrack(track_id);

        const binary_string = atob(data['audioBase64']);
        let bytes = new Uint8Array(binary_string.length);
        for (var i = 0; i < binary_string.length; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        const audioBuffer = await new window.AudioContext().decodeAudioData(bytes.buffer);
        const buf = await new Buffer(audioBuffer);
        this.deck.buffer = buf.get();

        console.log('TRACK LOADED');

        track.bpm = data['bpm'];
        track.waveform = data['waveform']
        track.cue_points = data['cue_points'];
        track.grid_start = data['grid_start'];
        track.title = data['title'];
        track.artist = data['artist'];
        track.key = data['key']
        this.setState({
            track: track
        });
        this.set_BPM(data['bpm']);
        const res = await buildTrackTimeline(data['grid_start'], this.deck.buffer.duration, data['bpm']);
        let t = this.state.track;
        t.timeline = res;
        this.setState({
            track: t,
            loading: false
        });

        this.renderTrackWaveform();
    }

    analyzeTrack = () => {
        var self = this;
        //Get the tempo and beat grid, then build the track's waveform
        getTempo(this.deck.buffer).then(function (result) {
            let track = self.state.track;
            track.bpm = result['bpm'];
            track.grid_start = result['gridStart'];
            self.setState({
                track: track
            });
            self.set_BPM(result['bpm'])
            buildWaveform(self.deck.buffer).then(function (waveform) {
                buildTrackTimeline(self.state.track.grid_start, self.deck.buffer.duration, self.state.track.bpm).then(function (result) {
                    let track = self.state.track;
                    track.timeline = result;
                    track.waveform = waveform;
                    self.setState({
                        track: track,
                        loading: false
                    })
                    self.renderTrackWaveform();
                    console.log('Analysis complete')
                })
            });
        })
    }

    //RENDERING//////////////////////////////////////////////////////////////////////
    /*Draw loop for the main waveform*/
    renderMainWaveform = () => {
        let self = this;
        setTimeout(function () { //throttle requestAnimationFrame to n fps
            renderWaveform(self.state.track.waveform, self.deck.buffer.duration, self.getPosition(), self.mainWaveformCanvasRef.current, self.state.track.bpm, self.state.track.timeline, self.state.track.cue_points, self.state.displayFactor);
            requestAnimationFrame(() => self.renderMainWaveform());
        }, 1000 / waveform_fps)
    }

    renderFullTimelineView = () => {
        let self = this;
        //Every .1 seconds
        setTimeout(function () { //throttle requestAnimationFrame to n fps
            if (self.deck.buffer) {
                let percentDone = self.getPosition() / self.deck.buffer.duration;

                let canvas = self.trackWaveformTimelineCanvasRef.current;
                const ctx = canvas.getContext("2d");

                ctx.clearRect(0, 0, canvas.width, canvas.height);

                //Draw center line
                ctx.beginPath();
                ctx.moveTo(canvas.width * percentDone, 0);
                ctx.lineTo(canvas.width * percentDone, canvas.height);
                ctx.lineWidth = 4;
                ctx.strokeStyle = "red";
                ctx.stroke();
                ctx.closePath();
                //Draw under line
                ctx.beginPath();
                ctx.moveTo(0, canvas.height);
                ctx.lineTo(canvas.width * percentDone, canvas.height);
                ctx.lineWidth = 4;
                ctx.strokeStyle = "white";
                ctx.stroke();
                ctx.closePath();

                //Draw Cue Points
                //Draw Cue Points
                let cue_points = self.state.track.cue_points;
                if (cue_points !== undefined && Object.keys(cue_points).length > 0) {
                    for (let cue in cue_points) {
                        let time = cue_points[cue];
                        let x = canvas.width * (time / self.deck.buffer.duration);
                        ctx.beginPath();
                        ctx.moveTo(x, 0);
                        ctx.lineTo(x, canvas.height);
                        ctx.lineWidth = 4;
                        ctx.strokeStyle = "cyan";
                        ctx.stroke();

                        //ctx.font = "12px verdana";
                        //ctx.fillText(cue, x + 5, 10);
                    }
                }

            }

            requestAnimationFrame(() => self.renderFullTimelineView());
        }, 200)
    }

    renderTrackWaveform = () => {
        renderFullWaveform(this.state.track.waveform, this.trackWaveformCanvasRef.current)
    }

    clearWaveforms = () => {
        let canvases = [this.trackWaveformCanvasRef.current, this.mainWaveformCanvasRef.current, this.trackWaveformTimelineCanvasRef.current];
        canvases.forEach(canvas => {
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        })
    }

    onWindowResize = () => {
        let screenWidth = window.innerWidth;
        if (screenWidth <= 760) {
            this.setState({
                displayFactor: 'mobile',
            })
        }
        else {
            this.setState({
                displayFactor: 'desktop',
            })
        }
    }

}
export default Deck;


//RENDERING/////////////////////////////////////////////////////////
function renderWaveform(waveform, duration, position, canvas, bpm, timeline, cue_points, displayFactor) {
    var renderOptions = {
        waveZoom: 18,
        lineWidth: 1
    }
    if (displayFactor === 'mobile') {
        renderOptions = {
            waveZoom: 10,
            lineWidth: 2
        }
    }

    if (waveform !== undefined && waveform.length > 0) {

        const scaleY = (amplitude, height) => {
            const range = 256;
            const offset = 128;
            return height - ((amplitude + offset) * height) / range;
        };

        let lookahead = renderOptions.waveZoom * (60 / bpm);
        let time_min = position - lookahead;
        let time_max = position + lookahead;

        let border_min = time_min / duration;
        let border_max = time_max / duration;

        let min_index = Math.round(border_min * waveform.length);
        let max_index = Math.round(border_max * waveform.length);

        let fill_before = [];
        let fill_after = [];
        if (min_index < 0) {
            let fill_amt = Math.abs(min_index);
            min_index = 0;
            fill_before = new Array(fill_amt).fill(0);
        }
        if (max_index >= waveform.length) {
            let fill_amt = max_index - (waveform.length - 1);
            max_index = waveform.length - 1;
            fill_after = new Array(fill_amt).fill(0);
        }

        var array_subset = waveform.slice(min_index, max_index);
        array_subset = [...fill_before, ...array_subset, ...fill_after];

        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        let total_points = array_subset.length;
        let x_travel = canvas.width / total_points;

        ctx.lineWidth = renderOptions.lineWidth;

        for (let x = 0; x < array_subset.length; x++) {
            let val = array_subset[x][0] * 100;
            ctx.strokeStyle = '#' + array_subset[x][1];

            ctx.beginPath();
            ctx.moveTo(x * x_travel, scaleY(-val, canvas.height) + 0.5);
            ctx.lineTo(x * x_travel, scaleY(val, canvas.height) + 0.5);
            ctx.stroke();
        }

        ctx.closePath();

        //Draw center line
        ctx.beginPath();
        ctx.moveTo(canvas.width / 2, 0);
        ctx.lineTo(canvas.width / 2, canvas.height);
        ctx.lineWidth = 4;
        ctx.strokeStyle = "white";
        ctx.stroke();

        //Draw track grid
        if (timeline !== undefined && timeline.length > 0) {
            //Filter out any timeline items not in view
            timeline = timeline.filter(function (item) {
                return item[0] >= time_min && item[0] <= time_max;
            });

            //Draw the grid lines
            for (var index in timeline) {
                let t = timeline[index][0];

                let x = (t - position + lookahead) / (lookahead * 2);
                x = x * canvas.width;

                ctx.beginPath();
                ctx.moveTo(x, 0);
                ctx.lineTo(x, canvas.height);
                ctx.lineWidth = 2;
                ctx.strokeStyle = "white";
                ctx.stroke();
            }
        }

        //Draw Cue Points
        if (cue_points !== undefined && Object.keys(cue_points).length > 0) {
            for (var cue in cue_points) {
                let time = cue_points[cue];
                //Don't draw if outside of our view
                if (time < time_min || time > time_max) {
                    continue
                }
                let x = (time - position + lookahead) / (lookahead * 2);
                x = x * canvas.width;
                ctx.beginPath();
                ctx.moveTo(x, 0);
                ctx.lineTo(x, canvas.height);
                ctx.lineWidth = 4;
                ctx.strokeStyle = "cyan";
                ctx.stroke();

                ctx.font = "12px verdana";
                ctx.fillText(cue, x + 5, 10);
            }
        }

    }
}

function renderFullWaveform(waveform, canvas) {
    const scaleY = (amplitude, height) => {
        const range = 256;
        const offset = 128;
        return height - ((amplitude + offset) * height) / range;
    };

    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    let interval = waveform.length / track_waveform_resolution;

    ctx.lineWidth = 2;

    for (let x = 0; x < waveform.length; x += interval) {
        let ind = parseInt(x)
        let val = 100 * waveform[ind][0];
        ctx.strokeStyle = '#' + waveform[ind][1];

        ctx.beginPath();
        ctx.moveTo(canvas.width * (ind / waveform.length), scaleY(-val, canvas.height) + 0.5);
        ctx.lineTo(canvas.width * (ind / waveform.length), scaleY(val, canvas.height) + 0.5);
        ctx.stroke();
    }
    ctx.closePath();
}

function buildTrackTimeline(grid_start, duration, bpm) {
    return new Promise(function (resolve, reject) {
        let timeline = []
        //Generate Grid
        grid_start = parseFloat(grid_start);
        let seconds_per_beat = 1 / (bpm / 60);

        //Add before grid start
        let calc_time = grid_start;
        while (calc_time > 0) {
            timeline.push([calc_time, "grid"]);
            calc_time -= seconds_per_beat;
        }
        timeline = timeline.reverse();

        //Add after grid start
        calc_time = grid_start;
        while (calc_time <= duration) {
            timeline.push([calc_time, "grid"]);
            calc_time += seconds_per_beat;
        }
        resolve(timeline);
    })
}