import React from 'react';
import * as Tone from 'tone';
import Deck from './components/Deck';
import Browser from './components/Browser';

//ANT components
import {Row, Col, message} from 'antd';
import logo from './images/AUDIOLANCHE-logo_white.png';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tracks:{
        1:{
          id:null,
          stream_url:null,
          bpm: null,
          grid_start: null,
          timeline:[],
          cue_points:{},
          waveform:[]
        },
        2:{
          id:null,
          stream_url:null,
          bpm: null,
          grid_start: null,
          timeline:[],
          cue_points:{},
          waveform:[]
        }
      },
      deck1_volume:null,
      deck2_volume:null,
      crossfader:0.5,

      isWalkthroughOpen:false,
    }
  }
  render () {
  return (
    <div className="App">
      <div style={{ width: '100%', height: '170px' }}></div>
      <Row>
        <Col xs={{ span: 24 }} md={{ span:12 }}>
        <Deck 
        number="1" 
        track={this.state.tracks[1]}
        color="rgb(180,230,250)"
        ref={instance => { this.deck1 = instance; }} 
        getExternalTempo={() => this.deck2.getPhase() }
        gain={this.state.deck1_volume}
        >
        </Deck>
        </Col>
        <Col xs={{ span: 24 }} md={{ span:12 }}>
        <Deck 
        number="2" 
        track={this.state.tracks[2]}
        color="rgb(250,230,100)" 
        ref={instance => { this.deck2 = instance; }} 
        getExternalTempo={() => this.deck1.getPhase() }
        gain={this.state.deck2_volume}
        >
        </Deck>
        </Col>
        </Row>
        <Row style={{border: '1px solid #818384'}} justify="space-between" align="middle">
          <Col style={{fontSize:'20px', textAlign:'center', width:'5px'}}>
            <img src={logo} alt="Audiolanche" style={{maxWidth:'300px', opacity:0.2}}/>
          </Col>
          <Col style={{paddingTop:'20px',paddingBottom:'25px'}}>
            <input className="crossfader" type="range" min="0" max="1" step="0.02" value={this.state.crossfader} onChange={(e) => this.crossfade(e)} style={{width:'300px'}} data-walkthrough="crossfader"/>
          </Col>
          {<Col style={{fontSize:'25px', textAlign:'center', width:'5px'}}>
            {/*<QuestionCircleOutlined style={{color:'white'}} onClick={(e) => this.setState({isWalkthroughOpen:true})}/>*/}
          </Col>}
        </Row>

        {/*Music Browser*/}
        <Browser loadTrack={this.loadTrack}/>
    </div>
  );
  }

  //CONTROLLERS//////////////////////////////
  loadTrack = (deckNumber, track) => {
    //Check if deck is playing, show warning if its playing
    var ref = null;
    if (deckNumber === 1) {
      ref = this.deck1;
    }
    else {
      ref = this.deck2; 
    }

    if (ref) {
      if (ref.state.playing) {
        message.error(`Stop Deck ${deckNumber} to load a new track`);
        return;
      }
    }

    let tracks = this.state.tracks;
    tracks[deckNumber] = {
      id:track.id,
      source_id: track.source_id,
      title:track.title,
      artist:track.artist,
      stream_url:track.stream_url,
      bpm: null,
      grid_start: null,
      timeline:[],
      cue_points:{},
      waveform:[]
    }
    this.setState({
      tracks:tracks
    })
  }

  crossfade = (event) => {
    let proportion = event.target.value;
    //Both volumes should be at max if crossfader is centered
    let deck1 = 2 - 2*proportion;
    let deck2 = 2*proportion;

    if (deck1 > 1) {
      deck1 = 1;
    }
    if (deck2 > 1) {
      deck2 = 1;
    }
    this.setState({
      crossfader:proportion,
      deck1_volume:Tone.gainToDb(deck1),
      deck2_volume:Tone.gainToDb(deck2)
    });
  }
}

export default App;
