import { renderAudioAtFrequencies } from "./util";

export const buildWaveform = (buffer) => {
    return new Promise((resolve, reject) => {
        let builder = new WaveformBuilder(buffer, 700000)
        builder.getWaveform().then(waveform => {
            builder = null;
            resolve(waveform)
        })
    })
}

export class WaveformBuilder {
    constructor(audioBuffer, resolution) {
        this.audioBuffer = audioBuffer;
        this.resolution = resolution;

        this.waveformdata = {};

        this.spectrums = {
            'Low': { 'lowBound': 0, 'highBound': 200 },
            'Medium': { 'lowBound': 200, 'highBound': 1500 },
            'High': { 'lowBound': 1500, 'highBound': 20000 },
        }
    }

    analyzeSpectrum(spectrumName) {
        let lowBound = this.spectrums[spectrumName]['lowBound']
        let highBound = this.spectrums[spectrumName]['highBound']
        let self = this;
        return new Promise((resolve, reject) => {
            renderAudioAtFrequencies(self.audioBuffer, lowBound, highBound).then(renderedBuffer => {
                getAmplitudeGraph(renderedBuffer, this.resolution).then(volumeGraph => {
                    renderedBuffer = null;
                    self.waveformdata[spectrumName] = volumeGraph
                    resolve(self)
                })
            })
        })
    }

    calculateWaveform() {
        let self = this;
        return new Promise((resolve, reject) => {
            let waveform = [];
            let low = self.waveformdata['Low']
            let mid = self.waveformdata['Medium']
            let high = self.waveformdata['High']

            for (var sample = 0; sample < low.length; sample += 10) {
                sample = parseInt(sample);

                let maxVol = Math.max(low[sample], mid[sample], high[sample])

                let red = parseInt((low[sample] / maxVol) * 255)
                let green = parseInt((mid[sample] / maxVol) * 255)
                let blue = parseInt((high[sample] / maxVol) * 255)

                waveform.push({
                    'v': parseInt(maxVol * 100),
                    'c': `rgb(${red},${green},${blue})`,
                });
            }
            resolve(waveform);;
        })
    }

    getWaveform() {
        let self = this;
        return new Promise((resolve, reject) => {
            self.analyzeSpectrum('Low').then(res => {
                self.analyzeSpectrum('Medium').then(res => {
                    self.analyzeSpectrum('High').then(res => {
                        resolve(self.calculateWaveform());
                    })
                })
            })
        })
    }
}

function getAmplitudeGraph(audioBuffer, resolution) {
    return new Promise((resolve, reject) => {
        let amplitudeGraph = [];
        let total_samples = audioBuffer.getChannelData(0).length;
        let increment = parseInt(total_samples / resolution);
        for (var sample = 0; sample < total_samples; sample += increment) {
            amplitudeGraph.push(Math.max(Math.abs(audioBuffer.getChannelData(0)[sample]), Math.abs(audioBuffer.getChannelData(1)[sample])));
            if (sample + increment >= total_samples) {
                resolve(amplitudeGraph)
            }
        }
    })
}