import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.baseURL = API_BASE_URL;

export const searchTracks = async (searchTerm) => {
    const res = await axios.get('/audio/tracks', { params: { q: searchTerm } });
    return res.data;
}

export const searchPlaylists = async (searchTerm) => {
    const res = await axios.get('/audio/playlists', { params: { q: searchTerm } });
    return res.data;
}

export const searchUsers = async (searchTerm) => {
    const res = await axios.get('/audio/users', { params: { q: searchTerm } });
    return res.data;
}

export const getPlaylist = async (playlistId) => {
    const res = await axios.get('/audio/playlist', {params: {id: playlistId}});
    return res.data;
}

export const getUserPlaylists = async (userId) => {
    const res = await axios.get('/audio/user/playlists', {params: {id: userId}});
    return res.data;
}

export const getUserTracks = async (userId) => {
    const res = await axios.get('/audio/user/tracks', {params: {id: userId}});
    return res.data;
}

export const analyzeSoundcloudTrack = async (trackId) => {
    const res = await axios.get('/audio/analyze', {params: {id: trackId}});
    return res.data;
}

export const getCollection = async () => {
    const res = await axios.get('/audio/collection');
    return res.data;
}