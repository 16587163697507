import {renderAudioAtFrequencies} from './util'

export const getTempo = (buffer) => {
    /*Gets the tempo and the beat grid of an audio buffer*/

    return new Promise(function (resolve, reject) {
        //Run the output through a lowpass filter to filter out anything but low end
        //Run the output through a highpass filter to filter out bass line (so only kick drums analyzed)
        renderAudioAtFrequencies(buffer, 100, 150).then(function (renderedBuffer) {
            let sampleRate = renderedBuffer.sampleRate ? renderedBuffer.sampleRate : 48000
            var peaks = getPeaks([renderedBuffer.getChannelData(0), renderedBuffer.getChannelData(1)], sampleRate);
            var groups = getIntervals(peaks, sampleRate);

            renderedBuffer = null;

            peaks.sort(function (a, b) {
                return b.volume - a.volume;
            });

            var top = groups.sort(function (intA, intB) {
                return intB.count - intA.count;
            }).splice(0, 5);

            //Pick the tempo of the top group
            var bpm = top[0]['tempo']
            //Pick the downbeat associated with the best group
            var grid_start = top[0]['downbeat_position'] / sampleRate;

            //TODO: Look backwards a bit to see if the downbeat is slightly before (sometimes the bass on kicks isn't maxed immediately due to compression)
            /*let spb = 60/bpm;
            let check2 = buffer.getChannelData(0).slice((grid_start-spb/6)*sampleRate,(grid_start+spb/6)*sampleRate);
            let check = renderedBuffer.getChannelData(0).slice((grid_start-spb/6)*sampleRate,(grid_start+spb/6)*sampleRate);

            check = check.map(sample => Math.abs(sample)*100);
            let resolution = 50;
            let interval2 = check.length/resolution;

            var waveform = []

            for (let x = 0; x < check.length; x+=interval2) {
                let from  = parseInt(x);
                let to = parseInt(x+interval2);
                let chunk = check.slice(from, to);
                let max = Math.max(...chunk);
                waveform.push(max);
            }
            
            console.log(waveform);

            const scaleY = (amplitude, height) => {
                const range = 256;
                const offset = 128;
                return height - ((amplitude + offset) * height) / range;
            };
        
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            let interval = 1;
            ctx.lineWidth = 1;
        
            for (let x = 0; x < waveform.length; x+=interval) {
                let ind = parseInt(x)
                let val = waveform[ind]
                ctx.strokeStyle = 'white';
        
                ctx.beginPath();
                ctx.moveTo(canvas.width*(ind/waveform.length), scaleY(-val, canvas.height) + 0.5);
                ctx.lineTo(canvas.width*(ind/waveform.length), scaleY(val, canvas.height) + 0.5);
                ctx.stroke();
            }
            ctx.closePath();

            ctx.beginPath();
            ctx.moveTo(canvas.width / 2, 0);
            ctx.lineTo(canvas.width / 2, canvas.height);
            ctx.lineWidth = 2;
            ctx.strokeStyle = "red";
            ctx.stroke();*/



            //Deprecated: Pick the loudest peak as the downbeat
            //var grid_start_old = peaks[0].position / (sampleRate);

            //Try to determine measure start
            /*var starts = {};
            for (var i = 0; i < 60; i++) {
                var t = peaks[i].position / (sampleRate);
                var matched = false;
                for (var key in starts) {
                    var measure_beat_offset = (Math.abs(t - key) / 60) * bpm % 4;
                    measure_beat_offset = Math.round(measure_beat_offset)
                    if (measure_beat_offset === 0) {
                        starts[key] += 1;
                        matched = true;
                        break;
                    }
                }
                if (matched === false) {
                    starts[t] = 0
                }
            }*/

            /*let maxval = 0
            let maxkey = grid_start
            for (var key2 in starts) {
                if (starts[key2] > maxval) {
                    maxval = starts[key2]
                    maxkey = key2
                }
            }*/

            resolve({
                'bpm': bpm,
                'gridStart': grid_start
            })
        });
    })
}


function getPeaks(data, sampleRate) {
    // What we're going to do here, is to divide up our audio into parts.
    // We will then identify, for each part, what the loudest sample is in that
    // part.
    // It's implied that that sample would represent the most likely 'beat'
    // within that part.
    // Each part is 0.5 seconds long - or 22,050 samples.
    // This will give us 60 'beats' - we will only take the loudest half of
    // those.
    // This will allow us to ignore breaks, and allow us to address tracks with
    // a BPM below 120.
    var partSize = sampleRate / 2,
        parts = data[0].length / partSize,
        peaks = [];

    for (var i = 0; i < parts; i++) {
        var max = 0;
        for (var j = i * partSize; j < (i + 1) * partSize; j++) {
            var volume = Math.max(Math.abs(data[0][j]), Math.abs(data[1][j]));
            if (!max || (volume > max.volume)) {
                max = {
                    position: j,
                    volume: volume
                };
            }
        }
        peaks.push(max);
    }

    // We then sort the peaks according to volume...
    peaks.sort(function (a, b) {
        return b.volume - a.volume;
    });

    // ...take the loundest half of those...
    peaks = peaks.splice(0, peaks.length * 0.5);

    // ...and re-sort it back based on position.
    peaks.sort(function (a, b) {
        return a.position - b.position;
    });

    return peaks;
}

function getIntervals(peaks, sampleRate) {
    // What we now do is get all of our peaks, and then measure the distance to
    // other peaks, to create intervals.  Then based on the distance between
    // those peaks (the distance of the intervals) we can calculate the BPM of
    // that particular interval.

    // The interval that is seen the most should have the BPM that corresponds
    // to the track itself.
    var groups = [];

    peaks.forEach(function (peak, index) {
        for (var i = 1; (index + i) < peaks.length && i < 10; i++) {
            let group = {
                tempo: (60 * sampleRate) / (peaks[index + i].position - peak.position),
                volume: peak.volume,
                downbeat_position: peak.position,
                count: 1
            };

            while (group.tempo < 90) {
                group.tempo *= 2;
            }

            while (group.tempo > 180) {
                group.tempo /= 2;
            }

            group.tempo = Math.round(group.tempo);

            if (groups.some(interval => interval.tempo === group.tempo)) {
                let ind = groups.findIndex(interval => interval.tempo === group.tempo)
                //Increment our count
                groups[ind].count += 1;
                //Set the downbeat if the peak in our new group is louder
                if (groups[ind].volume < group.volume) {
                    groups[ind].volume = group.volume
                    groups[ind].downbeat_position = group.downbeat_position
                }
            }
            else {
                groups.push(group)
            }


            /*if (!(groups.some(function (interval) {
                return (interval.tempo === group.tempo ? interval.count++ : 0);
            }))) {
                groups.push(group);
            }*/
        }
    });
    return groups;
}